nav.navbar .navbar-container .navbar-button {
  padding: 8px 15px;
  margin-right: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 0px 20px ;
  position: fixed; /* Fixes navbar at the top */
  top: 0; /* Aligns navbar to the top of the viewport */
  height: 70px;
  width: 100%; /* Ensures navbar stretches across the full width */
  z-index: 1000; /* Ensures navbar stays on top of other content */
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.navbar-small-cont {
  display: flex; /* Establishes a flex container */
  justify-content: center; /* Horizontally centers the buttons in the container */
  align-items: center; /* Vertically centers the buttons */
  gap: 10px;
}

.navbar-button {
  padding: 8px 15px; /* Adds padding inside the button for better appearance */
  margin-right: 20px; /* Increase margin to space it from the right edge */
  border: none; /* Optional: removes default border */
  border-radius: 5px; /* Optional: adds rounded corners */
  background-color: #007bff; /* Optional: adds background color */
  color: white; /* Optional: changes text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 1rem; /* Adjust font size as necessary */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 50px; /* Adjust based on your logo's size */
}

  @media (max-width: 768px) {
    .navbar {
      height: 60px;
      padding: 0 10px;
    }
  
    .navbar-logo {
      flex-shrink: 0; /* Prevents the logo container from shrinking */
      width: 30%; /* Or you can adjust this if it's still too large */
      display: flex; /* This will help center the image inside the container */
      align-items: center; /* Aligns the image vertically */
      justify-content: center; /* Centers the image horizontally */
    }
  
    .navbar-logo img {
      width: auto; /* Adjusts width automatically to maintain aspect ratio */
      height: 50%; /* Adjust the height to make the logo smaller */
      max-width: 100%; /* Ensures the logo image does not overflow its container */
      max-height: 100%; /* Ensures the logo image respects the container's height */
    }
  
    .navbar-small-cont {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end; /* Align items to the end (right) */
      align-items: center;
      padding: 0;
    }
  
    .navbar-button {
      padding: 8px 10px;
      margin: 0;
      flex: 0 0 auto;
    }
  
    .navbar-username {
      display: none; /* Hide username on mobile */
    }
}
