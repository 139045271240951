@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav.navbar .navbar-container .navbar-button {
  padding: 8px 15px;
  margin-right: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 0px 20px ;
  position: fixed; /* Fixes navbar at the top */
  top: 0; /* Aligns navbar to the top of the viewport */
  height: 70px;
  width: 100%; /* Ensures navbar stretches across the full width */
  z-index: 1000; /* Ensures navbar stays on top of other content */
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.navbar-small-cont {
  display: flex; /* Establishes a flex container */
  justify-content: center; /* Horizontally centers the buttons in the container */
  align-items: center; /* Vertically centers the buttons */
  grid-gap: 10px;
  gap: 10px;
}

.navbar-button {
  padding: 8px 15px; /* Adds padding inside the button for better appearance */
  margin-right: 20px; /* Increase margin to space it from the right edge */
  border: none; /* Optional: removes default border */
  border-radius: 5px; /* Optional: adds rounded corners */
  background-color: #007bff; /* Optional: adds background color */
  color: white; /* Optional: changes text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 1rem; /* Adjust font size as necessary */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 50px; /* Adjust based on your logo's size */
}

  @media (max-width: 768px) {
    .navbar {
      height: 60px;
      padding: 0 10px;
    }
  
    .navbar-logo {
      flex-shrink: 0; /* Prevents the logo container from shrinking */
      width: 30%; /* Or you can adjust this if it's still too large */
      display: flex; /* This will help center the image inside the container */
      align-items: center; /* Aligns the image vertically */
      justify-content: center; /* Centers the image horizontally */
    }
  
    .navbar-logo img {
      width: auto; /* Adjusts width automatically to maintain aspect ratio */
      height: 50%; /* Adjust the height to make the logo smaller */
      max-width: 100%; /* Ensures the logo image does not overflow its container */
      max-height: 100%; /* Ensures the logo image respects the container's height */
    }
  
    .navbar-small-cont {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end; /* Align items to the end (right) */
      align-items: center;
      padding: 0;
    }
  
    .navbar-button {
      padding: 8px 10px;
      margin: 0;
      flex: 0 0 auto;
    }
  
    .navbar-username {
      display: none; /* Hide username on mobile */
    }
}

.genButton {
  padding: 10px 20px; /* Padding inside the button */
  background-color: #1b181a; /* Dark background color */
  color: #96baff; /* White text color for contrast */
  border: 2px solid #343436; /* Slightly lighter or darker border color for subtle contrast */
  border-color: #96baff;
  cursor: pointer; /* Cursor changes to a pointer to indicate it's clickable */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Center the text inside the button */
  text-decoration: none; /* Remove underlines if it's an anchor tag */
  transition: background-color 0.2s ease-in-out; /* Smooth transition for the background color */
  display: inline-block; /* Ensure it's treated as a clickable button */
  font-size: 16px; /* Adjust to your preference */
  font-weight: bold; /* Optional: if you want the font to be bold */
}

.genButton:hover {
  background-color: #949494; /* Lighter background color on hover for visual feedback */
}

.genButton.active {
  background-color: #96baff;
}
.home {
  background-color: black;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}

.header {
  background: url("/static/media/Frame 2.e96773a5.png") no-repeat center center;
  background-size: cover;
  height: 120vh;
  position: relative;
  color: white;
}

.navbar {
  z-index: 10;
}

.collab-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.home .container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);

  all: unset; /* Unset all inherited and default styles */
  display: block; /* Ensures it is a block-level element */
  padding: 0;
  width: auto;
  height: auto;
  background: none;
  border: none;
  box-sizing: content-box; /* Default box-sizing value */
  font: inherit; /* Inherit font settings from parent */
  color: inherit; /* Inherit text color from parent */
  position: static; /* Default position */
  float: none; /* Reset any float */
  clear: none; /* Reset clear */
  z-index: auto; /* Default z-index */
  overflow: visible; /* Default overflow */
  clip: auto; /* Default clip */
  visibility: visible; /* Default visibility */
  cursor: auto; /* Default cursor */
  opacity: 1; /* Default opacity */
  pointer-events: auto; /* Default pointer events */
  -webkit-user-select: auto;
          user-select: auto; /* Default user-select */
  vertical-align: baseline; /* Default vertical align */
  text-align: inherit; /* Inherit text-align from parent */
  white-space: normal; /* Default white-space */
  word-spacing: normal; /* Default word-spacing */
  letter-spacing: normal; /* Default letter-spacing */
  text-transform: none; /* Default text-transform */
  text-decoration: none; /* Default text-decoration */
  text-shadow: none; /* Default text-shadow */
  line-height: normal; /* Default line-height */
  outline: none; /* Default outline */
  box-shadow: none; /* Default box-shadow */
  transition: none; /* Default transition */
  transform: none; /* Default transform */
  animation: none; /* Default animation */
  content: normal; /* Default content */
  list-style: none; /* Default list-style */
  border-collapse: separate; /* Default border-collapse */
  border-spacing: 0; /* Default border-spacing */
  caption-side: top; /* Default caption-side */
  empty-cells: show; /* Default empty-cells */
  table-layout: auto; /* Default table-layout */
  direction: ltr; /* Default direction */
  unicode-bidi: normal; /* Default unicode-bidi */
  quotes: auto; /* Default quotes */
  counter-reset: none; /* Default counter-reset */
  counter-increment: none; /* Default counter-increment */
  resize: none; /* Default resize */
  /* Add more resets as needed */
}

.home .GenPage {
  /* Unset all properties */
  all: unset;

  /* Ensure it is a block-level element */
  display: block; 

  /* Reset common properties */
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  background: none;
  border: none;
  box-sizing: content-box;
  font: inherit;
  color: inherit;
  position: static;
  float: none;
  clear: none;
  z-index: auto;
  overflow: visible;
  clip: auto;
  visibility: visible;
  cursor: auto;
  opacity: 1;
  pointer-events: auto;
  -webkit-user-select: auto;
          user-select: auto;
  vertical-align: baseline;
  text-align: inherit;
  white-space: normal;
  word-spacing: normal;
  letter-spacing: normal;
  text-transform: none;
  text-decoration: none;
  text-shadow: none;
  line-height: normal;
  outline: none;
  box-shadow: none;
  transition: none;
  transform: none;
  animation: none;
  content: normal;
  list-style: none;
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  empty-cells: show;
  table-layout: auto;
  direction: ltr;
  unicode-bidi: normal;
  quotes: auto;
  counter-reset: none;
  counter-increment: none;
  resize: none;

  /* You can re-apply your specific styles after resetting if needed */
  /* Example: background-color: #fff; */
  /* Example: padding: 20px; */
}

.navbar-nav li a {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
  color: rgba(150, 186, 255, 1) !important;
  font-weight: 600;
}

.banner {
  height: calc(70vh - 56px); 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.banner-content {
  z-index: 1;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5);  */
  z-index: 0;
}

.banner-content h1 {
  background: linear-gradient(360deg, #4A84F1 9.84%, #96BAFF 71.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 80px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.banner-content p {
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

/* colaburation section starts */
.collab_wrapper {
  padding: 2rem 0rem;
}

.collab_wrapper h3 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  font-family: "Poppins", sans-serif;

}
.collab_wrapper p {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins", sans-serif;

}
.collab_wrapper button {
  background-color: #96BAFF;
  color: #fff;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 30px;
  font-size: 20px;
  padding: 0.5rem 2rem;
}
.collabe-right-content h6 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.collabe-right-content h5 {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  margin-top: 0.5rem;
}
.collabe-right-content p {
  font-size: 20px;
  color: #dbdbdb;
}
/* colaburation section ends */

/* camparison starts */
.camparison_wrapper .camp-header h3 {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  padding: 2rem 0rem;
  color: #fff;
}
.camparison_wrapper ul li {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: 35px;
}
.card-title {
  font-size: 35px;
  font-weight: bold;
  padding: 25px;
  font-family: "Poppins", sans-serif;
  color: #fff;

}
.camparison_wrapper .card {
  border: 2px solid #96BAFF;
  border-radius: 22px;
  background-color: transparent;
  position: relative;

}
.camparison_wrapper .card-lines {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.camparison_wrapper .card-body {
  margin-bottom: 9rem !important; /* Ensure there's space for the lines */
}
.card-lines {
  margin-bottom: 1rem;
}

.card-lines .line1,
.card-lines .line2 {
  margin-bottom: 50px;
}


.card-lines .line1 img,
.card-lines .line2 img {
  display: block;
}

/* Responsive adjustments */

/* vedio section */

.video_wrapper {
  position: relative;
}

.video-thumb {
  position: relative;
  display: inline-block;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-thumb img {
  width: 100%;
  height: auto;
  display: block;
}

.play-btn img {
  width: 80px; /* Adjust the size of the play button as needed */
  height: 80px;
}

/* footer starts */
.first-col p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 1rem 0rem;
  font-family: "Poppins", sans-serif;
}
.menu-col h5 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.menu-col ul {
  padding: 0 !important;
  margin-top: 1rem;
}
.menu-col ul li {
  list-style: none;
  line-height: 45px;
}
.menu-col ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.contact-us-col h5 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.contact-us-col h4 {
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: rgba(150, 186, 255, 1);
  padding: 1rem 0rem;
}
.email-icon span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.call-icon span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.footer-line {
  border: 1px solid rgb(175, 175, 175);
}
.mt-5 {
  margin-top: 3rem; /* Adjust the margin as needed */
  position: relative; /* This is needed for absolute positioning of the icons */
}

.footer-line {
  width: 100%;
  height: 2px; /* Adjust the height as needed */
  background-color: #000; /* Change the color as needed */
  position: relative; /* This is needed for absolute positioning of icons */
}

.social-icons {
  display: flex;
  justify-content: center;
  position: absolute; /* This will position the icons relative to the footer line */
  top: 0; /* Adjust this value if you want to fine-tune the position */
  left: 50%; /* Move to the center horizontally */
  transform: translate(-50%, -50%); /* Adjust the position to be exactly centered */
}

.social-icons .fb-icon,
.social-icons .twitter-icon,
.social-icons .tube-icon {
  display: flex;
  align-items: center;
}

.social-icons img {
  width: 30px; /* Adjust the size as needed */
  height: auto;
}
.copyright p {
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

/* footer ends */

@media (max-width: 1200px) {
  .banner-content h1 {
    font-size: 60px;
  }
  .banner-content p {
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  .banner-content h1 {
    font-size: 50px;
  }
  .banner-content p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .banner-content h1 {
    font-size: 40px;
  }
  .banner-content p {
    font-size: 18px;
  }
  .banner {
    height: calc(50vh - 56px);
  }
  .header {
    background: url(/static/media/mob.e2e6e132.png) no-repeat center center !important;
    background-size: cover;
    height: 80vh;
    position: relative;
    color: white;
    width: 100%;
}
.contact-us-col {
  text-align: center;
}
}

@media (max-width: 576px) {
  .banner-content h1 {
    /* font-size: 70px; */
  }
  .banner-content p {
    font-size: 16px;
  }
}

/* Existing styles */
.GenPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #191919;
}

body {
  margin: 0;
  background-color: #191919; /* The desired background color */
}

.main-content {
  margin-top: 70px;
  background-color: #191919;
}

.container {
  border-radius: 8px;
  margin: 10px;
  padding: 30px;
  width: 340px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #383437;
  border: 1px solid #96baff;
  line-height: .8;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 310px;
}

.p {
  font-size: 0.8em; /* Set font size to 0.8 of the parent element's font size */
  white-space: normal; /* Allow text to wrap onto the next line */
  overflow-wrap: break-word; /* Ensure long words break and wrap onto the next line */
  word-wrap: break-word; /* Ensure long words break and wrap onto the next line */
  margin: 0; /* Reset margin to prevent unexpected spacing */
}


h1 {
  font-family: 'Roboto', sans-serif;
  color: white; /* Example color */
  font-weight: 700; /* Example font weight */
}

p {
  font-family: 'Roboto' sans-serif; /* Default serif font */
  font-size: 16px; /* Default font size */
  line-height: normal; /* Default line height */
  margin-top: .4em; /* Default top margin */
  margin-bottom: .4em; /* Default bottom margin */
  color: inherit; /* Inherit color from parent */
}

.input {
  width: 320px;
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input[type="text"] {
  height: 40px;
}

textarea.input {
  height: 100px;
  resize: vertical;
}

.GenLinkButton {
  margin: 20px 0;
}

/* /dashboard css */

/* Adjusted styles for Dashboard page */
.dashboard {
  background-color: #1b181a ;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure dashboard spans full width */
  height: 100vh;
  color: white; /* White text for the entire dashboard */
  margin-top: 108px;
}

.tabs {
  display: flex;
  width: 100%; /* Ensure tab container spans full width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  justify-content: space-around; /* Space out tabs evenly */
  position: fixed;
  top: 0; /* Align to the top or below any fixed header */
  left: 0; /* Align to the left side of the screen */
  background-color: #383437; /* Set to the color that matches your design */
  z-index: 1000; /* Ensure tabs stay on top of other content */
  border-bottom: 1px solid #96baff; /* Add a border if needed */
  margin-top: 70px;
}

@media (max-width: 768px) {
  .tabs {
    margin-top:60px;
  }
}

/* Adjust button styles within .tabs to fill the space equally */
.tabs button {
  display: flex; /* Establishes flex container */
  align-items: center; /* Vertically centers the flex items */
  justify-content: center; /* Horizontally centers the flex items */
  flex-grow: 1; /* Allows each button to grow and fill the container */
  background-color: #9d9d9d;
  border: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 0px;
  max-height: 40px;
}

.seller-tab {
  border-radius: 0px;
}

.new-collab-template {
  width: 320px;
  padding-top: 10px;
  background-color: #383437;
  margin-top: 20px;
  margin-bottom: 0px;
}

.main-dashboard {
  overflow-x: auto;
  width: 800px;
  margin-top:20px;
}

.sub-tabs-buttons{
  display: flex; /* Establishes a flex container */
  justify-content: center; /* Horizontally centers the buttons in the container */
  align-items: center; /* Vertically centers the buttons */
  grid-gap:20px;
  gap:20px; /* Adds space between the buttons */
  margin-top:-10px;
  margin-bottom: 20px;
}

.dashboard-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.transaction-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #383437;
}

.transaction-item:last-child {
  border-bottom: none; /* Removes border from the last item */
}

.transaction-item button {
  background-color: #446a77; /* Style for 'Details' button */
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.dashboard-table-header {
  display: flex;
  text-align: left;
  margin-top: auto;
}

@media (max-width: 768px) {
  .main-dashboard {
    display: block; /* Switch from flex to block layout for vertical stacking */
    overflow-x: auto; /* Enable horizontal scrolling for the table */
    width: 100%; /* Ensure it takes the full width of the viewport */
    margin-top: 20px;
    margin-left: 50px;
  }

  .sub-tabs-buttons {
    display: flex;
    justify-content: flex-start; /* Aligns tabs to the start */
    overflow-x: auto; /* Allows horizontal scrolling if more tabs are added */
    width: 100%;
    margin-bottom: 10px; /* Adjust spacing between tabs and the table header */
  }

  .dashboard-table {
    min-width: 800px; /* Set a minimum width for your table to ensure it can scroll horizontally */
    width: auto; /* Allows the table to expand as needed */
    margin-top: 10px; /* Space between the header and the table */
  }

  .dashboard-table-header {
    width: 100%; /* Ensure the header aligns with the table width */
    text-align: left; /* Aligns the header text to the left */
    margin-bottom: 10px; /* Provides spacing between the header and the table */
  }

  /* Ensure table rows and cells are not squished */
  .dashboard-table table,
  .dashboard-table th,
  .dashboard-table td {
    min-width: 120px; /* Minimum width for table cells, adjust as needed */
  }
}


/* end /dashboard css */
/* You may need to adjust the styles further to match the exact look of the image */
/* createNewCollabTemplate */

.info{
  color: white;
  font-size: 20px;
  font-style: bold;
  text-align: left;
  align-self: flex-start;
}
