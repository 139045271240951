.genButton {
  padding: 10px 20px; /* Padding inside the button */
  background-color: #1b181a; /* Dark background color */
  color: #96baff; /* White text color for contrast */
  border: 2px solid #343436; /* Slightly lighter or darker border color for subtle contrast */
  border-color: #96baff;
  cursor: pointer; /* Cursor changes to a pointer to indicate it's clickable */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Center the text inside the button */
  text-decoration: none; /* Remove underlines if it's an anchor tag */
  transition: background-color 0.2s ease-in-out; /* Smooth transition for the background color */
  display: inline-block; /* Ensure it's treated as a clickable button */
  font-size: 16px; /* Adjust to your preference */
  font-weight: bold; /* Optional: if you want the font to be bold */
}

.genButton:hover {
  background-color: #949494; /* Lighter background color on hover for visual feedback */
}

.genButton.active {
  background-color: #96baff;
}