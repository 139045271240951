@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.home {
  background-color: black;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}

.header {
  background: url('./images/Frame 2.png') no-repeat center center;
  background-size: cover;
  height: 120vh;
  position: relative;
  color: white;
}

.navbar {
  z-index: 10;
}

.collab-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.home .container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);

  all: unset; /* Unset all inherited and default styles */
  display: block; /* Ensures it is a block-level element */
  padding: 0;
  width: auto;
  height: auto;
  background: none;
  border: none;
  box-sizing: content-box; /* Default box-sizing value */
  font: inherit; /* Inherit font settings from parent */
  color: inherit; /* Inherit text color from parent */
  position: static; /* Default position */
  float: none; /* Reset any float */
  clear: none; /* Reset clear */
  z-index: auto; /* Default z-index */
  overflow: visible; /* Default overflow */
  clip: auto; /* Default clip */
  visibility: visible; /* Default visibility */
  cursor: auto; /* Default cursor */
  opacity: 1; /* Default opacity */
  pointer-events: auto; /* Default pointer events */
  user-select: auto; /* Default user-select */
  vertical-align: baseline; /* Default vertical align */
  text-align: inherit; /* Inherit text-align from parent */
  white-space: normal; /* Default white-space */
  word-spacing: normal; /* Default word-spacing */
  letter-spacing: normal; /* Default letter-spacing */
  text-transform: none; /* Default text-transform */
  text-decoration: none; /* Default text-decoration */
  text-shadow: none; /* Default text-shadow */
  line-height: normal; /* Default line-height */
  outline: none; /* Default outline */
  box-shadow: none; /* Default box-shadow */
  transition: none; /* Default transition */
  transform: none; /* Default transform */
  animation: none; /* Default animation */
  content: normal; /* Default content */
  list-style: none; /* Default list-style */
  border-collapse: separate; /* Default border-collapse */
  border-spacing: 0; /* Default border-spacing */
  caption-side: top; /* Default caption-side */
  empty-cells: show; /* Default empty-cells */
  table-layout: auto; /* Default table-layout */
  direction: ltr; /* Default direction */
  unicode-bidi: normal; /* Default unicode-bidi */
  quotes: auto; /* Default quotes */
  counter-reset: none; /* Default counter-reset */
  counter-increment: none; /* Default counter-increment */
  resize: none; /* Default resize */
  /* Add more resets as needed */
}

.home .GenPage {
  /* Unset all properties */
  all: unset;

  /* Ensure it is a block-level element */
  display: block; 

  /* Reset common properties */
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  background: none;
  border: none;
  box-sizing: content-box;
  font: inherit;
  color: inherit;
  position: static;
  float: none;
  clear: none;
  z-index: auto;
  overflow: visible;
  clip: auto;
  visibility: visible;
  cursor: auto;
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
  vertical-align: baseline;
  text-align: inherit;
  white-space: normal;
  word-spacing: normal;
  letter-spacing: normal;
  text-transform: none;
  text-decoration: none;
  text-shadow: none;
  line-height: normal;
  outline: none;
  box-shadow: none;
  transition: none;
  transform: none;
  animation: none;
  content: normal;
  list-style: none;
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  empty-cells: show;
  table-layout: auto;
  direction: ltr;
  unicode-bidi: normal;
  quotes: auto;
  counter-reset: none;
  counter-increment: none;
  resize: none;

  /* You can re-apply your specific styles after resetting if needed */
  /* Example: background-color: #fff; */
  /* Example: padding: 20px; */
}

.navbar-nav li a {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
  color: rgba(150, 186, 255, 1) !important;
  font-weight: 600;
}

.banner {
  height: calc(70vh - 56px); 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.banner-content {
  z-index: 1;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5);  */
  z-index: 0;
}

.banner-content h1 {
  background: linear-gradient(360deg, #4A84F1 9.84%, #96BAFF 71.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 80px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.banner-content p {
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

/* colaburation section starts */
.collab_wrapper {
  padding: 2rem 0rem;
}

.collab_wrapper h3 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  font-family: "Poppins", sans-serif;

}
.collab_wrapper p {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins", sans-serif;

}
.collab_wrapper button {
  background-color: #96BAFF;
  color: #fff;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 30px;
  font-size: 20px;
  padding: 0.5rem 2rem;
}
.collabe-right-content h6 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.collabe-right-content h5 {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  margin-top: 0.5rem;
}
.collabe-right-content p {
  font-size: 20px;
  color: #dbdbdb;
}
/* colaburation section ends */

/* camparison starts */
.camparison_wrapper .camp-header h3 {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  padding: 2rem 0rem;
  color: #fff;
}
.camparison_wrapper ul li {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: 35px;
}
.card-title {
  font-size: 35px;
  font-weight: bold;
  padding: 25px;
  font-family: "Poppins", sans-serif;
  color: #fff;

}
.camparison_wrapper .card {
  border: 2px solid #96BAFF;
  border-radius: 22px;
  background-color: transparent;
  position: relative;

}
.camparison_wrapper .card-lines {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.camparison_wrapper .card-body {
  margin-bottom: 9rem !important; /* Ensure there's space for the lines */
}
.card-lines {
  margin-bottom: 1rem;
}

.card-lines .line1,
.card-lines .line2 {
  margin-bottom: 50px;
}


.card-lines .line1 img,
.card-lines .line2 img {
  display: block;
}

/* Responsive adjustments */

/* vedio section */

.video_wrapper {
  position: relative;
}

.video-thumb {
  position: relative;
  display: inline-block;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-thumb img {
  width: 100%;
  height: auto;
  display: block;
}

.play-btn img {
  width: 80px; /* Adjust the size of the play button as needed */
  height: 80px;
}

/* footer starts */
.first-col p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 1rem 0rem;
  font-family: "Poppins", sans-serif;
}
.menu-col h5 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.menu-col ul {
  padding: 0 !important;
  margin-top: 1rem;
}
.menu-col ul li {
  list-style: none;
  line-height: 45px;
}
.menu-col ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.contact-us-col h5 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.contact-us-col h4 {
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: rgba(150, 186, 255, 1);
  padding: 1rem 0rem;
}
.email-icon span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.call-icon span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.footer-line {
  border: 1px solid rgb(175, 175, 175);
}
.mt-5 {
  margin-top: 3rem; /* Adjust the margin as needed */
  position: relative; /* This is needed for absolute positioning of the icons */
}

.footer-line {
  width: 100%;
  height: 2px; /* Adjust the height as needed */
  background-color: #000; /* Change the color as needed */
  position: relative; /* This is needed for absolute positioning of icons */
}

.social-icons {
  display: flex;
  justify-content: center;
  position: absolute; /* This will position the icons relative to the footer line */
  top: 0; /* Adjust this value if you want to fine-tune the position */
  left: 50%; /* Move to the center horizontally */
  transform: translate(-50%, -50%); /* Adjust the position to be exactly centered */
}

.social-icons .fb-icon,
.social-icons .twitter-icon,
.social-icons .tube-icon {
  display: flex;
  align-items: center;
}

.social-icons img {
  width: 30px; /* Adjust the size as needed */
  height: auto;
}
.copyright p {
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

/* footer ends */

@media (max-width: 1200px) {
  .banner-content h1 {
    font-size: 60px;
  }
  .banner-content p {
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  .banner-content h1 {
    font-size: 50px;
  }
  .banner-content p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .banner-content h1 {
    font-size: 40px;
  }
  .banner-content p {
    font-size: 18px;
  }
  .banner {
    height: calc(50vh - 56px);
  }
  .header {
    background: url(./images/mob.png) no-repeat center center !important;
    background-size: cover;
    height: 80vh;
    position: relative;
    color: white;
    width: 100%;
}
.contact-us-col {
  text-align: center;
}
}

@media (max-width: 576px) {
  .banner-content h1 {
    /* font-size: 70px; */
  }
  .banner-content p {
    font-size: 16px;
  }
}
