/* Existing styles */
.GenPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #191919;
}

body {
  margin: 0;
  background-color: #191919; /* The desired background color */
}

.main-content {
  margin-top: 70px;
  background-color: #191919;
}

.container {
  border-radius: 8px;
  margin: 10px;
  padding: 30px;
  width: 340px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #383437;
  border: 1px solid #96baff;
  line-height: .8;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 310px;
}

.p {
  font-size: 0.8em; /* Set font size to 0.8 of the parent element's font size */
  white-space: normal; /* Allow text to wrap onto the next line */
  overflow-wrap: break-word; /* Ensure long words break and wrap onto the next line */
  word-wrap: break-word; /* Ensure long words break and wrap onto the next line */
  margin: 0; /* Reset margin to prevent unexpected spacing */
}


h1 {
  font-family: 'Roboto', sans-serif;
  color: white; /* Example color */
  font-weight: 700; /* Example font weight */
}

p {
  font-family: 'Roboto' sans-serif; /* Default serif font */
  font-size: 16px; /* Default font size */
  line-height: normal; /* Default line height */
  margin-top: .4em; /* Default top margin */
  margin-bottom: .4em; /* Default bottom margin */
  color: inherit; /* Inherit color from parent */
}

.input {
  width: 320px;
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input[type="text"] {
  height: 40px;
}

textarea.input {
  height: 100px;
  resize: vertical;
}

.GenLinkButton {
  margin: 20px 0;
}

/* /dashboard css */

/* Adjusted styles for Dashboard page */
.dashboard {
  background-color: #1b181a ;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure dashboard spans full width */
  height: 100vh;
  color: white; /* White text for the entire dashboard */
  margin-top: 108px;
}

.tabs {
  display: flex;
  width: 100%; /* Ensure tab container spans full width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  justify-content: space-around; /* Space out tabs evenly */
  position: fixed;
  top: 0; /* Align to the top or below any fixed header */
  left: 0; /* Align to the left side of the screen */
  background-color: #383437; /* Set to the color that matches your design */
  z-index: 1000; /* Ensure tabs stay on top of other content */
  border-bottom: 1px solid #96baff; /* Add a border if needed */
  margin-top: 70px;
}

@media (max-width: 768px) {
  .tabs {
    margin-top:60px;
  }
}

/* Adjust button styles within .tabs to fill the space equally */
.tabs button {
  display: flex; /* Establishes flex container */
  align-items: center; /* Vertically centers the flex items */
  justify-content: center; /* Horizontally centers the flex items */
  flex-grow: 1; /* Allows each button to grow and fill the container */
  background-color: #9d9d9d;
  border: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 0px;
  max-height: 40px;
}

.seller-tab {
  border-radius: 0px;
}

.new-collab-template {
  width: 320px;
  padding-top: 10px;
  background-color: #383437;
  margin-top: 20px;
  margin-bottom: 0px;
}

.main-dashboard {
  overflow-x: auto;
  width: 800px;
  margin-top:20px;
}

.sub-tabs-buttons{
  display: flex; /* Establishes a flex container */
  justify-content: center; /* Horizontally centers the buttons in the container */
  align-items: center; /* Vertically centers the buttons */
  gap:20px; /* Adds space between the buttons */
  margin-top:-10px;
  margin-bottom: 20px;
}

.dashboard-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.transaction-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #383437;
}

.transaction-item:last-child {
  border-bottom: none; /* Removes border from the last item */
}

.transaction-item button {
  background-color: #446a77; /* Style for 'Details' button */
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.dashboard-table-header {
  display: flex;
  text-align: left;
  margin-top: auto;
}

@media (max-width: 768px) {
  .main-dashboard {
    display: block; /* Switch from flex to block layout for vertical stacking */
    overflow-x: auto; /* Enable horizontal scrolling for the table */
    width: 100%; /* Ensure it takes the full width of the viewport */
    margin-top: 20px;
    margin-left: 50px;
  }

  .sub-tabs-buttons {
    display: flex;
    justify-content: flex-start; /* Aligns tabs to the start */
    overflow-x: auto; /* Allows horizontal scrolling if more tabs are added */
    width: 100%;
    margin-bottom: 10px; /* Adjust spacing between tabs and the table header */
  }

  .dashboard-table {
    min-width: 800px; /* Set a minimum width for your table to ensure it can scroll horizontally */
    width: auto; /* Allows the table to expand as needed */
    margin-top: 10px; /* Space between the header and the table */
  }

  .dashboard-table-header {
    width: 100%; /* Ensure the header aligns with the table width */
    text-align: left; /* Aligns the header text to the left */
    margin-bottom: 10px; /* Provides spacing between the header and the table */
  }

  /* Ensure table rows and cells are not squished */
  .dashboard-table table,
  .dashboard-table th,
  .dashboard-table td {
    min-width: 120px; /* Minimum width for table cells, adjust as needed */
  }
}


/* end /dashboard css */
/* You may need to adjust the styles further to match the exact look of the image */
/* createNewCollabTemplate */

.info{
  color: white;
  font-size: 20px;
  font-style: bold;
  text-align: left;
  align-self: flex-start;
}